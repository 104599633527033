import React, { useState, useEffect } from "react";
import { isElectron, screen, BrowserWindow } from "../utils/electron";
import PencilIcon from "./icons/pencil.svg";
import { getUrl, patchUrl } from "../utils/utils";
import config from "../utils/config";
import { useNavigate } from "react-router-dom";
import Dialog from "./Dialog";
import authService from "../utils/authService";

let screenWindow = [
  //assuming maximum monitors being 10
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];

export default function Banners(props) {
  const [screenType, setScreenType] = useState([
    //assuming we have never more than 10 monitors
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const [displays, setDisplays] = useState([]);
  const [updateAvailable, setUpdateAvailable] = useState("");
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setBreadCrumbs([
      {
        name: isElectron() ? "Site Worship Desktop" : "Site Worship",
        link: `${process.env.PUBLIC_URL}/`,
      },
      ...props.breadcrumbs,
    ]);
  }, [props.breadcrumbs]);

  async function checkUpdate() {
    if (!window.location.href.startsWith("http://localhost:")) {
      try {
        let version = await getUrl(
          "https://siteworship.agolzer.com/siteworship.build"
        );
        if (version.data) {
          version = version.data;
        }
        const posNewline = version.indexOf("\n");
        if (posNewline !== -1) {
          version = version.substring(0, posNewline);
        }
        setUpdateAvailable(version !== config.getBuild() ? version : "");
      } catch (e) {
        console.log("Unable to check update", e);
        setUpdateAvailable("");
      }
    }
  }

  useEffect(() => {
    let interval = undefined;
    if (config.isElectron()) {
      getDisplays().then((displays1) => {
        setDisplays(displays1);
      });
      interval = setInterval(async () => {
        const displays1 = await getDisplays();
        setDisplays(displays1);
      }, 10000);
    }

    checkUpdate().then(() => {
      // setInterval(async () => {
      //   await checkUpdate();
      // }, 60 * 1000);
    });
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  function isScreenUrl() {
    if (window.location.pathname === `${process.env.PUBLIC_URL}/screen`) {
      return true;
    }
    if (
      window.location.pathname === `${process.env.PUBLIC_URL}/screen-novideo`
    ) {
      return true;
    }
    if (window.location.pathname === `${process.env.PUBLIC_URL}/screen-stage`) {
      return true;
    }
    if (
      window.location.pathname === `${process.env.PUBLIC_URL}/screen-subtitles`
    ) {
      return true;
    }
    if (window.location.pathname === `${process.env.PUBLIC_URL}/screen-notes`) {
      return true;
    }
    return false;
  }

  function renderHeader() {
    return (
      <div className="banner flex-row-fixed d-print-none">
        {" "}
        {breadCrumbs.map((breadcrumb, index) => {
          if (index === breadCrumbs.length - 1) {
            if (breadcrumb.editField) {
              return (
                <React.Fragment key={index}>
                  {breadcrumb.name}
                  <button
                    style={{ border: 0 }}
                    onClick={async () => {
                      let value = await Dialog.showPrompt(
                        breadcrumb.editLabel || "Change value",
                        breadcrumb.editLabel || "Change value",
                        breadcrumb[breadcrumb.editField]
                      );
                      if (value) {
                        try {
                          await patchUrl("/api/playlists/" + breadcrumb._id, {
                            name: value,
                          });
                          let breadCrumbs1 = [...breadCrumbs];
                          breadCrumbs1[breadCrumbs1.length - 1].name = value;
                          setBreadCrumbs(breadCrumbs1);
                        } catch (error) {
                          alert("Save failed: " + error);
                        }
                      }
                    }}
                  >
                    <img src={PencilIcon} alt="Edit" />
                  </button>
                </React.Fragment>
              );
            } else {
              return breadcrumb.name;
            }
          } else {
            return (
              <React.Fragment key={index}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(breadcrumb.link);
                  }}
                  style={{
                    border: "0",
                    backgroundColor: "#DDDDDD",
                  }}
                >
                  {" "}
                  {breadcrumb.name}{" "}
                </button>
                &nbsp;&gt;&nbsp;
              </React.Fragment>
            );
          }
        })}{" "}
        <div className="flex-row" style={{ color: "gray" }}>
          {props.msg}
        </div>
        <div style={{ color: "#FF0000" }}>
          {updateAvailable && (
            <React.Fragment>
              Update {updateAvailable} available.{" "}
              <button
                style={{ color: "#FF0000" }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Install Update
              </button>
            </React.Fragment>
          )}
          {breadCrumbs.length === 1 && authService.getEmail() && (
            <button
              style={{ border: "0px", backgroundColor: "#dddddd" }}
              onClick={() => {
                Dialog.showConfirm(
                  "Logout",
                  "Do you really want to log out " +
                    authService.getProfile().name +
                    "?"
                ).then((button) => {
                  if (button === "Yes") {
                    window.location.href = `${process.env.PUBLIC_URL}/logout`;
                  }
                });
              }}
            >
              Logout
            </button>
          )}
        </div>
      </div>
    );
  }

  /* get all displays with the main one being the first one */
  async function getDisplays() {
    const displays = await screen.getAllDisplays();
    const mainDisplay = displays.filter((display) => {
      return display.bounds.x === 0 && display.bounds.y === 0;
    });
    const externalDisplays = displays.filter((display) => {
      return display.bounds.x !== 0 || display.bounds.y !== 0;
    });
    return [...mainDisplay, ...externalDisplays];
  }

  async function handleScreen(type, index) {
    let screenType1 = [...screenType];
    screenType1[index] = type;
    if (type === undefined) {
      if (screenWindow[index]) {
        screenWindow[index].destroy();
        screenWindow[index] = undefined;
      }
      return;
    }

    let urlPrefix = "http://localhost:8199";
    if (type.startsWith("remote-")) {
      type = type.substring("remote-".length);
      urlPrefix = "http://" + config.getRemoteScreenIp() + ":8199";
    }

    const displays1 = displays;

    if (displays1.length === 0) {
      if (!isElectron()) {
        window.open(urlPrefix + "/" + type, "_blank");
      } else {
        console.log("No display found");
      }
      return;
    }
    setScreenType(screenType1);

    let bounds = { ...displays1[index].bounds };
    if (index === 0) {
      bounds.x = bounds.x + bounds.width - bounds.width / 3;
      bounds.y = bounds.y + bounds.height - bounds.height / 3;
      bounds.width = bounds.width / 3;
      bounds.height = bounds.height / 3;
    }
    if (!screenWindow[index]) {
      const options = {
        ...bounds,
        fullscreen: index > 0,
        closable: false,
      };
      screenWindow[index] = new BrowserWindow(options);
    }
    await screenWindow[index].loadURL(urlPrefix + "/" + type);
  }

  function renderScreenSelection(index) {
    const screenType1 = screenType[index];

    return (
      <div className="btn-group" key={index}>
        <button
          type="button"
          className="btn btn-secondary dropdown-toggle dropdown-toggle-split mr-1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {index}:{" "}
          {!screenType1
            ? !isElectron()
              ? "Create Screen"
              : "No Screen"
            : screenType1 === "screen-novideo"
            ? "Screen No Video"
            : screenType1 === "screen"
            ? "Screen"
            : screenType1 === "screen-stage"
            ? "Stage"
            : screenType1 === "screen-subtitles"
            ? "Subtitles"
            : screenType1 === "screen-notes"
            ? "Notes"
            : screenType1 === "remote-screen-novideo"
            ? "Screen No Video (" + config.getRemoteScreenIp() + ")"
            : screenType1 === "remote-screen"
            ? "Screen (" + config.getRemoteScreenIp() + ")"
            : screenType1 === "remote-screen-stage"
            ? "Stage (" + config.getRemoteScreenIp() + ")"
            : screenType1 === "remote-screen-subtitles"
            ? "Subtitles (" + config.getRemoteScreenIp() + ")"
            : screenType1 === "remote-screen-notes"
            ? "Notes (" + config.getRemoteScreenIp() + ")"
            : screenType1}
        </button>

        <div className="dropdown-menu">
          <button
            className="dropdown-item"
            onClick={() => handleScreen("screen", index)}
          >
            Screen
          </button>
          <button
            className="dropdown-item"
            onClick={() => handleScreen("screen-stage", index)}
          >
            Screen - Stage
          </button>
          <button
            className="dropdown-item"
            onClick={() => handleScreen("screen-novideo", index)}
          >
            Screen - No video
          </button>
          <button
            className="dropdown-item"
            onClick={() => handleScreen("screen-subtitles", index)}
          >
            Screen - Subtitles
          </button>
          <button
            className="dropdown-item"
            onClick={() => handleScreen("screen-notes", index)}
          >
            Screen - Notes
          </button>
          {config.getRemoteScreenIp() && (
            <>
              <button
                className="dropdown-item"
                onClick={() => handleScreen("remote-screen", index)}
              >
                Screen ({config.getRemoteScreenIp()})
              </button>
              <button
                className="dropdown-item"
                onClick={() => handleScreen("remote-screen-stage", index)}
              >
                Screen - Stage ({config.getRemoteScreenIp()})
              </button>
              <button
                className="dropdown-item"
                onClick={() => handleScreen("remote-screen-novideo", index)}
              >
                Screen - No video ({config.getRemoteScreenIp()})
              </button>
              <button
                className="dropdown-item"
                onClick={() => handleScreen("remote-screen-subtitles", index)}
              >
                Screen - Subtitles ({config.getRemoteScreenIp()})
              </button>
              <button
                className="dropdown-item"
                onClick={() => handleScreen("remote-screen-notes", index)}
              >
                Screen - Notes ({config.getRemoteScreenIp()})
              </button>
            </>
          )}
          {screenType && isElectron() && (
            <button
              className="dropdown-item"
              onClick={() => handleScreen(undefined, index)}
              disabled={!screenType[index]}
            >
              Hide
            </button>
          )}
        </div>
      </div>
    );
  }

  function renderFooter() {
    if (!authService.getEmail()) {
      return null;
    }

    if (!isElectron() && breadCrumbs.length === 1) {
      return (
        <div className="banner d-print-none">{renderScreenSelection(0)}</div>
      );
    }

    return (
      displays &&
      displays.length > 0 && (
        <div className="banner d-print-none">
          {displays.map((display, index) => {
            return renderScreenSelection(index);
          })}
        </div>
      )
    );
  }

  return (
    <React.Fragment>
      {!isScreenUrl() && renderHeader()}
      {props.children}
      {!isScreenUrl() && renderFooter()}
    </React.Fragment>
  );
}
