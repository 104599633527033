import React, { Component } from "react";
import Control from "./controls/Control";
import WebSocket from "./WebSocket";
import DragDropList from "./controls/DragDropList";
import Config from "../utils/config";

export default class Queue extends Component {
  static instance = undefined;

  state = { showChildren: true };

  async componentDidMount() {
    if (this.props.setme) {
      this.props.setme(this);
    }

    if (!Queue.instance) {
      Queue.instance = this;
      WebSocket.on("ended", (msg) => {
        msg = JSON.parse(msg);
        let firstPlaying = -1;
        this.props.items.forEach((item, index) => {
          if (localStorage.getItem("playing_id") === item._id) {
            firstPlaying = index;
          }
        });

        if (firstPlaying >= 0) {
          let src = msg.src;
          if (src.indexOf("?") !== -1) {
            src = src.substring(0, src.indexOf("?"));
          }
          if (src.startsWith(Config.getMediaDownloadUrl())) {
            src = src.substring(Config.getMediaDownloadUrl().length);
            src = decodeURIComponent(src);
          }
          if (src === this.props.items[firstPlaying].path) {
            if (firstPlaying + 1 < this.getListCount()) {
              Queue.instance.playIndex(firstPlaying + 1);
            } else {
              Queue.instance.stopIndex(firstPlaying);
            }
          }
        }
      });
    }
  }

  moveItemsTo = (items, dest) => {
    let ids = items.map((item) => item._id);
    let newItems = [];
    this.props.items.forEach((item, index) => {
      if (dest === index) {
        newItems = [...newItems, ...items];
      }
      if (!ids.includes(item._id)) {
        newItems.push(item);
      }
    });
    this.props.setItems(newItems);
  };

  getIndexFromId(id) {
    let index = 0;
    for (let i = 0; i < this.props.items.length; i++) {
      let item = this.props.items[i];
      if (item._id === id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  getItemFromId(id) {
    for (let i = 0; i < this.props.items.length; i++) {
      let item = this.props.items[i];
      if (item._id === id) {
        return item;
      }
    }
    return null;
  }

  getItemByIndex = (index) => {
    if (index >= 0 && index < this.props.items.length) {
      return this.props.items[index];
    }
    return undefined;
  };

  playIndex = (index) => {
    if (index < 0 || index >= this.props.items.length) {
      return;
    }

    let items = [...this.props.items];
    let modified = false;
    let adjustPreview = false;
    for (let i = 0; i < items.length; i++) {
      if (i === index) {
        if (localStorage.getItem("playing_id") !== items[i]._id) {
          items[i] = { ...items[i] };
          localStorage.setItem("playing_id", items[i]._id);
          modified = true;
        }
      } else {
        if (localStorage.getItem("playing_id") === items[i]._id) {
          items[i] = { ...items[i] };
          localStorage.removeItem("playing_id");
          modified = true;
          if (this.props.isSelected[items[i]._id]) {
            adjustPreview = true;
          }
        }
      }
    }
    if (modified) {
      let item = this.getItemByIndex(index);
      this.props.setItems(items, false);
      WebSocket.emit("play", item, this.props.items);
      if (item.type === "bible") {
        WebSocket.emit("subtitles", item, this.props.items);
      }
    }
    if (adjustPreview) {
      this.onSelect(index, index);
    }
  };

  previewIndex = (from, to) => {
    const items = this.props.items;
    WebSocket.emitLocal("preview", items[to], items);
    if (from > to) {
      [from, to] = [to, from];
    }
    let isSelected = {};
    for (let i = 0; i < items.length; i++) {
      if (i >= from && i <= to) {
        isSelected[items[i]._id] = true;
      } else {
        isSelected[items[i]._id] = false;
      }
    }
    this.props.setIsSelected(isSelected);
  };

  stopIndex = (index) => {
    let items = [...this.props.items];
    let isModified = false;
    for (let i = 0; i < items.length; i++) {
      if (localStorage.getItem("playing_id") === items[i]._id) {
        isModified = true;
        items[i] = { ...items[i] };
        localStorage.removeItem("playing_id");
      }
    }
    if (isModified) {
      this.props.setItems(items, false);
      let item = this.getItemByIndex(index);
      WebSocket.emit("stop", item);
    }
  };

  deleteItems = (items) => {
    let newItems = [];
    let ids = items.map((item) => item._id);
    this.props.items.forEach((item) => {
      if (!ids.includes(item._id)) {
        newItems.push(item);
      }
    });
    this.props.setItems(newItems);
  };

  onSelect = (from, to) => {
    if (this.props.activeTab === "Presentation") {
      this.playIndex(to);
    }
    this.previewIndex(from, to);
  };

  isPlaying = (index) => {
    if (
      index < this.props.items.length &&
      this.props.items[index] &&
      localStorage.getItem("playing_id") === this.props.items[index]._id
    ) {
      return true;
    } else {
      return false;
    }
  };

  onExecute = (index) => {
    if (!this.isPlaying(index)) {
      this.playIndex(index);
    } else {
      this.stopIndex(index);
    }
  };

  onScrollUp = () => {
    WebSocket.emit("scroll_up", {}, []);
  };

  onScrollDown = () => {
    WebSocket.emit("scroll_down", {}, []);
  };

  getListCount() {
    return this.props.items.length;
  }

  renderItems() {
    let items = this.props.items;
    if (this.props.songsheets) {
      items = items.filter((item) => item.type === "lyrics");
    }
    if (!items) {
      return "Loading...";
    }
    return items.map((item, index) => {
      item = { ...item };
      if (!this.state.showChildren) {
        if (item.isChild) {
          return null;
        }
        let groupCount = 1;
        let selectedChild = this.props.isSelected[items[index]._id] ? 1 : 0;
        while (
          index + groupCount < items.length &&
          items[index + groupCount].isChild
        ) {
          if (this.props.isSelected[items[index + groupCount]._id]) {
            selectedChild = groupCount + 1;
          }
          groupCount++;
        }
        if (groupCount > 1) {
          item.name = item.name + " (" + selectedChild + "/" + groupCount + ")";
        }
      }
      return (
        <div
          key={item._id}
          style={{
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "row",
            backgroundColor:
              localStorage.getItem("playing_id") === item._id
                ? "#db7d7d"
                : this.props.isSelected[item._id]
                ? "#CCCCCC"
                : "#FFFFFF",
          }}
        >
          <Control
            songsheets={this.props.songsheets}
            activeTab={this.props.activeTab}
            item={item}
            id={item._id}
            key={item._id}
          >
            {(this.props.showItem && this.props.showItem(item)) ||
              item.name ||
              "invalid"}
          </Control>
        </div>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={this.props.display}
          style={{
            flex: "1 1 auto",
            margin: "0",
            padding: "0",
            textAlign: "justify",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            overflowY: this.props.print ? null : "auto",
          }}
        >
          {this.props.songsheets ? (
            this.renderItems()
          ) : (
            <div style={{ flex: "0 0 300px" }}>
              <div style={{ backgroundColor: "lightgray" }}>
                <input
                  type="checkbox"
                  checked={this.state.showChildren}
                  onChange={() =>
                    this.setState({ showChildren: !this.state.showChildren })
                  }
                ></input>
                Show Children
              </div>
              <DragDropList
                id="playlist"
                items={this.props.items}
                multiSelect={true}
                moveItemsTo={this.moveItemsTo}
                draggable={!this.props.readonly}
                onSelect={(from, to) => {
                  this.onSelect(from, to);
                }}
                onExecute={(index) => {
                  this.onExecute(index);
                }}
                onScrollUp={() => {
                  this.onScrollUp();
                }}
                onScrollDown={() => {
                  this.onScrollDown();
                }}
                onDelete={(items) => {
                  this.deleteItems(items);
                }}
                onEdit={(index) => {
                  const id = this.getItemByIndex(index)._id;
                  this.handleEdit(id);
                }}
                showChildren={this.state.showChildren}
                convertToDragItem={(item) => {
                  return { ...item };
                }}
              >
                {this.renderItems()}
              </DragDropList>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
