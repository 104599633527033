import React, { useState, useEffect } from "react";
import DragDropList from "../controls/DragDropList";
import Dropzone from "./Dropzone";
import "./Attachments.css";
import Progress from "./Progress";
import CheckCircleImage from "./baseline-check_circle_outline-24px.svg";
import TrashIcon from "../icons/trash.svg";
import PencilIcon from "../icons/pencil.svg";
import DownloadIcon from "../icons/download.svg";
import PlayIcon from "../icons/play.svg";
import { getUrl, deleteUrl, postUrl } from "../../utils/utils";
import Config from "../../utils/config";
import Dialog from "../Dialog";
import Mime from "mime";
import { v4 as uuidv4 } from "uuid";
import SortUpIcon from "../icons/sort-up.svg";
import SortDownIcon from "../icons/sort-down.svg";

export default function Attachments(props) {
  const [attachments, setAttachments] = useState([]);
  const [filteredAttachments, setFilteredAttachments] = useState([]);
  const [uploading, setUploading] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const page = 1;
  const pageSize = 500;
  const [percentage, setPercentage] = useState("");
  const [sortBy, setSortBy] = useState("modified");
  const [sortAsc, setSortAsc] = useState(false);
  const [lastPlayed, setLastPlayed] = useState(undefined);

  async function loadPlaylistsAll() {
    if (lastPlayed === undefined) {
      setLastPlayed({});
      let response = await getUrl("/api/playlists/all");
      if (response.status === 200) {
        const entries = [...response.data];
        let lp = {};
        entries.forEach((entry) => {
          const name = entry.name?.substr(0, 8) || "";
          if (name.startsWith("20") && entry.items) {
            entry.items.forEach((item) => {
              if (item.type === "media") {
                if (!lp[item.path]) {
                  lp[item.path] = name;
                } else if (name > lp[item.path]) {
                  lp[item.path] = name;
                }
              }
            });
          }
        });
        setLastPlayed(lp);
      }
    }
  }

  function getApiDirectory() {
    return Config.getHost() + "/api/media";
  }

  async function loadMedia() {
    let response = await getUrl("/api/media");
    if (response.status === 200) {
      let attachments =
        typeof response.data === "string"
          ? JSON.parse(response.data)
          : response.data;
      setAttachments(attachments);
      setFilteredAttachments([...attachments]);
    }
  }

  useEffect(() => {
    loadMedia();
  }, []);

  useEffect(() => {
    let filtered = [...attachments];
    let lsearch = search.toLowerCase();
    filtered = filtered.filter(
      (file) => String(file.filename).toLowerCase().indexOf(lsearch) !== -1
    );
    filtered.sort((a1, a2) => {
      let value1 = a1[sortBy];
      let value2 = a2[sortBy];
      if (sortBy === "lastPlayed") {
        value1 =
          (lastPlayed && lastPlayed["/" + a1.hash + "/" + a1.filename]) || "";
        value2 =
          (lastPlayed && lastPlayed["/" + a2.hash + "/" + a2.filename]) || "";
        let ret = value1 < value2 ? 1 : value1 > value2 ? -1 : 0;
        if (sortAsc) {
          ret = -ret;
        }
        if (value1 === "") {
          return 1;
        }
        if (value2 === "") {
          return -1;
        }
        return ret;
      } else if (sortBy === "size") {
        value1 = parseInt(value1);
        value2 = parseInt(value2);
      } else if (sortBy === "modified") {
        value1 = String(Math.floor(parseInt(value1) / 24 / 3600));
        value2 = String(Math.floor(parseInt(value2) / 24 / 3600));
        if (value1 === value2) {
          value1 = String(a2["filename"]).toLowerCase();
          value2 = String(a1["filename"]).toLowerCase();
        }
      } else {
        value1 = String(value1).toLowerCase();
        value2 = String(value2).toLowerCase();
      }
      let ret = value1 < value2 ? 1 : value1 > value2 ? -1 : 0;
      if (sortAsc && sortBy !== "lastPlayed") {
        ret = -ret;
      }
      return ret;
    });

    setFilteredAttachments([...filtered]);
  }, [attachments, lastPlayed, search, sortBy, sortAsc]);

  function handleChange({ currentTarget: input }) {
    setAttachments(input.value);
  }

  function handleBlur({ currentTarget: input }) {
    setAttachments(input.value);
  }

  function onFilesAdded(files) {
    for (let i = 0; i < files.length; i++) {
      sendRequest(files[i]);
    }
  }

  async function sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          setUploading((uploading2) => {
            let uploading1 = [...uploading2];
            let i = 0;
            let percentage1 = (event.loaded / event.total) * 100;
            let found = false;
            for (i = 0; i < uploading1.length; i++) {
              if (uploading1[i].name === file.name) {
                found = true;
                uploading1[i].percentage = percentage1;
                break;
              }
            }
            if (!found) {
              uploading1.push({
                name: file.name,
                percentage: percentage1,
              });
            }
            if (percentage !== percentage1) {
              setPercentage(percentage1);
            }
            return uploading1;
          });
        }
      });

      req.upload.addEventListener("error", (event) => {
        let files = [...uploading];
        let i = 0;
        for (i = 0; i < files.length; i++) {
          if (files[i].filename === file.name) {
            files[i].error = "error";
            break;
          }
        }
        handleChange({
          currentTarget: { name: props.name, value: files },
        });
        reject(req.response);
      });

      req.onload = () => {
        setUploading((uploading2) => {
          let uploading1 = [...uploading2];
          let found = false;
          let allComplete = true;
          for (let i = 0; i < uploading1.length; i++) {
            if (uploading1[i].name === file.name) {
              uploading1[i]._id = req.response._id;
              found = true;
            }
            if (!uploading1[i]._id) {
              allComplete = false;
            }
          }
          if (!found) {
            let newFile = {
              name: file.name,
              _id: req.response._id,
              percentage: 100,
            };
            uploading1 = [...uploading1, newFile];
          }
          if (allComplete) {
            loadMedia().then(() => {
              setUploading([]);
            });
          }
          return uploading1;
        });
      };

      const formData = new FormData();
      let filename = file.name;
      if (
        props.playlistName &&
        !filename.startsWith(props.playlistName + "_")
      ) {
        filename = props.playlistName + "_" + filename;
      }
      formData.append("file", file, filename);

      req.open("POST", getApiDirectory());
      req.responseType = "json";
      req.send(formData);
    });
  }

  function renderProgress(file) {
    return (
      <div className="ProgressWrapper">
        <Progress progress={file.percentage} />
        <img
          className="CheckIcon"
          alt="done"
          src={CheckCircleImage}
          style={{
            opacity: file._id ? 0.5 : 0,
          }}
        />
      </div>
    );
  }

  function getBucketObjectUrl(file) {
    return (
      "https://storage.googleapis.com/storage/v1/b/siteworship-13ef9c571978490c92798eab5b8ddfa5/o/" +
      encodeURIComponent(file.hash.substring(0, 2) + "/" + file.hash)
    );
  }
  function renderFileButtons(file) {
    let fileName = file.filename;
    return (
      <div className="flex-row-fixed">
        {!props.uploadOnly && (
          <button
            style={{
              fontSize: "100%",
              border: "0",
              padding: "0",
            }}
            onClick={async (e) => {
              e.preventDefault();
              let toDelete = [...selectedItems];
              if (toDelete.length === 0) {
                toDelete = [file];
              }
              let button = await Dialog.showConfirm(
                "Deleting File",
                <React.Fragment>
                  <div>Do you really want to delete following file(s)?</div>
                  {toDelete.map((si) => (
                    <div>{si.filename}</div>
                  ))}
                </React.Fragment>
              );
              if (button === "Yes") {
                try {
                  let requests = await Promise.all(
                    toDelete.map((si) => deleteUrl(getBucketObjectUrl(si)))
                  );
                  let failedFiles = "";
                  requests.forEach((r, index) => {
                    if (r.status !== 200 && r.status !== 204) {
                      if (failedFiles.length > 0) {
                        failedFiles += ", ";
                      }
                      failedFiles += toDelete[index].filename;
                    }
                  });
                  if (failedFiles.length > 0) {
                    Dialog.showMessage("Unable to delete file(s)", failedFiles);
                  }
                } catch (exc) {
                  Dialog.showMessage(
                    "Unable to delete some or all files",
                    "Unable to delete some or all files"
                  );
                }

                loadMedia();
              }
            }}
          >
            <img src={TrashIcon} alt="" />
          </button>
        )}
        &nbsp;
        {!props.uploadOnly && (
          <button
            disabled={true}
            style={{
              fontSize: "100%",
              border: "0",
              padding: "0",
            }}
            onClick={async (e) => {
              e.preventDefault();
              let value = prompt("Rename file", fileName);
              if (value) {
                let newName = value;
                let posValue = value.lastIndexOf(".");
                let posFile = fileName.lastIndexOf(".");
                if (
                  posValue !== -1 &&
                  posFile !== -1 &&
                  value.substring(posValue) !== fileName.substring(posFile)
                ) {
                  newName = value + fileName.substring(posFile);
                }

                let response = await postUrl(
                  "/api/media/" +
                    fileName +
                    "?newName=" +
                    encodeURIComponent(newName)
                );
                if (response.status === 200) {
                  let files = attachments;
                  for (let i = 0; i < files.length; i++) {
                    if (files[i].name === fileName) {
                      files[i].name = newName;
                    }
                  }
                  handleChange({
                    currentTarget: {
                      name: props.name,
                      value: files,
                    },
                  });
                  handleBlur({
                    currentTarget: {
                      name: props.name,
                      value: files,
                    },
                  });
                } else {
                  alert(
                    "Unable to rename file " + fileName + " " + response.status
                  );
                }
              }
            }}
          >
            <img src={PencilIcon} alt="" />
          </button>
        )}
        &nbsp;
        {!props.uploadOnly && (
          <a
            role="button"
            href={
              getApiDirectory() +
              "/" +
              encodeURIComponent(file.hash) +
              "/" +
              encodeURIComponent(fileName)
            }
            download={fileName}
          >
            <img src={DownloadIcon} alt="" />
          </a>
        )}
        <button
          style={{
            fontSize: "100%",
            border: "0",
            padding: "0",
          }}
          onClick={async (e) => {
            let contentType = Mime.getType(fileName);
            if (contentType === "video/quicktime") {
              contentType = "video/mp4";
            }
            let body = fileName + " " + contentType;
            let url =
              "https://storage.googleapis.com/download/storage/v1/b/siteworship-13ef9c571978490c92798eab5b8ddfa5/" +
              "o/" +
              encodeURIComponent(file.hash.substring(0, 2) + "/" + file.hash) +
              "?alt=media";
            if (contentType.startsWith("image/")) {
              body = (
                <img
                  alt={fileName}
                  src={url}
                  style={{ width: "100%", height: "100%" }}
                />
              );
            } else if (contentType.startsWith("video/")) {
              body = (
                <video width="100%" height="100%" controls>
                  <source src={url} type={contentType} />
                </video>
              );
            } else if (contentType.startsWith("audio/")) {
              body = (
                <audio width="100%" height="100%" controls>
                  <source src={url} type={contentType} />
                </audio>
              );
            }
            Dialog.showDialog(fileName, () => body, ["Ok"]);
          }}
        >
          <img src={PlayIcon} alt="" />
        </button>
      </div>
    );
  }

  function renderFileItem(file) {
    let fileName = file.filename;
    let modified = file.modified;
    if (modified) {
      let d = new Date(modified);
      modified =
        String(d.getMonth() + 1) +
        "/" +
        String(d.getDate()) +
        "/" +
        String(d.getFullYear()).substring(2);
    }
    let size = file.size;
    if (size >= 1024 * 1024 * 1024) {
      size = String(Math.round((size / 1024 / 1024 / 1024) * 100) / 100) + " G";
    } else if (size >= 1024 * 1024) {
      size = String(Math.round(size / 1024 / 1024)) + " M";
    } else if (size >= 1024) {
      size = String(Math.round(size / 1024)) + " K";
    }
    let lp =
      (lastPlayed && lastPlayed["/" + file.hash + "/" + file.filename]) || "";
    return (
      <div key={fileName} className="flex-row" style={{ minWidth: 0 }}>
        {!props.readOnly && (
          <div className="flex-row" style={{ width: "100%" }}>
            <div className="flex-row-fixed media_column_buttons">
              {renderFileButtons(file)}
            </div>
            <div className="flex-row media_column_filename">{fileName}</div>
            <div className="flex-row-fixed media_column_date">{modified}</div>
            <div className="flex-row-fixed media_column_size">{size}</div>
            <div className="flex-row-fixed media_column_lastPlayed">{lp}</div>
          </div>
        )}
        {renderProgress(file)}
      </div>
    );
  }

  function convertToDragItem(item) {
    return {
      type: "media",
      path: "/" + item.hash + "/" + encodeURIComponent(item.filename),
      name: item.filename,
      controls: true,
      playNext: true,
      _id: uuidv4(),
    };
  }

  function renderActionButtons() {
    return (
      <div
        style={{
          flex: "0 0 auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!props.uploadOnly && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {props.onAdd && (
              <React.Fragment>
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    props.onAdd(
                      selectedItems.map((item) => convertToDragItem(item))
                    );
                  }}
                >
                  Add to playlist
                </button>
                &nbsp;&nbsp;
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    props.onAdd([
                      {
                        name: "Blank",
                        type: "blank",
                        url: "",
                        _id: uuidv4(),
                      },
                    ]);
                  }}
                >
                  Add Blank
                </button>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }

  function renderSearchBar() {
    return (
      <div className="flex-column-fixed" style={{ width: "100%" }}>
        <input
          value={search}
          placeholder="Search"
          onChange={({ currentTarget: input }) => {
            setSearch(input.value);
          }}
          style={{ flex: "1 1 auto", width: "100%" }}
        />
      </div>
    );
  }

  function sortIcon(column) {
    const opacity = sortBy === column ? "100%" : "20%";
    const icon = sortAsc ? SortUpIcon : SortDownIcon;
    return (
      <img
        src={icon}
        alt=""
        style={{ opacity: opacity, width: "25px", height: "25px" }}
      />
    );
  }

  let items = [];
  for (
    let i = (page - 1) * pageSize;
    i < filteredAttachments.length && i < page * pageSize;
    i++
  ) {
    let file = filteredAttachments[i];
    items.push(file);
  }

  return (
    <div
      className="Content"
      style={{
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        overflowY: "auto",
      }}
    >
      {!props.readOnly && <div style={{ flex: "0 0 auto" }}></div>}
      <div className="Files flex-column">
        <div className="flex-row-fixed">
          <Dropzone onFilesAdded={onFilesAdded}>Upload</Dropzone>
          {renderActionButtons()}
        </div>
        {renderSearchBar()}
        {filteredAttachments.length === 0 ? "None" : ""}
        {uploading.map((item) => {
          return (
            <div key={"uploading_" + item.name}>
              <div className="Filename">&nbsp; {item.name}</div>
              {renderProgress(item)}
            </div>
          );
        })}
        <Dropzone onFilesAdded={onFilesAdded} dragOnly={true}>
          <div
            className="flex-row-fixed"
            style={{ width: "100%", fontWeight: "bold" }}
          >
            <div className="flex-row-fixed media_column_buttons"></div>
            <div
              className="flex-row media_column_filename"
              onClick={() => {
                if (sortBy === "filename") {
                  setSortAsc(!sortAsc);
                } else {
                  setSortBy("filename");
                }
              }}
            >
              Filename
              {sortIcon("filename")}
            </div>
            <div
              className="flex-row-fixed media_column_date"
              onClick={() => {
                if (sortBy === "modified") {
                  setSortAsc(!sortAsc);
                } else {
                  setSortBy("modified");
                }
              }}
            >
              {sortIcon("modified")}Modified
            </div>
            <div
              className="flex-row-fixed media_column_size"
              onClick={() => {
                if (sortBy === "size") {
                  setSortAsc(!sortAsc);
                } else {
                  setSortBy("size");
                }
              }}
            >
              {sortIcon("size")}Size
            </div>
            <div
              className="flex-row-fixed media_column_size"
              onClick={() => {
                loadPlaylistsAll();
                if (sortBy === "lastPlayed") {
                  setSortAsc(!sortAsc);
                } else {
                  setSortBy("lastPlayed");
                }
              }}
            >
              {sortIcon("lastPlayed")}Played
            </div>
          </div>
          <DragDropList
            id="attachments"
            items={items}
            multiSelect={true}
            draggable={true}
            convertToDragItem={convertToDragItem}
            onSelect={(from, to) => {
              if (to < from) {
                [from, to] = [to, from];
              }
              setSelectedItems(items.slice(from, to + 1));
              if (props.onSelect) {
                const item = items[from];
                props.onSelect(item, from);
              }
            }}
            onExecute={() => {
              props.onAdd(selectedItems.map((item) => convertToDragItem(item)));
            }}
          >
            {items.map((item) => (
              <div
                key={"/" + item.hash + "/" + item.filename}
                style={{
                  backgroundColor: selectedItems
                    .map((item) => item.path + "/" + item.filename)
                    .includes(item.path + "/" + item.filename)
                    ? "#CCCCCC"
                    : "#FFFFFF",
                }}
              >
                {renderFileItem(item)}
              </div>
            ))}
          </DragDropList>
        </Dropzone>
      </div>
    </div>
  );
}
