import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getUrl, postUrl, putUrl } from "../utils/utils";
import Attachments from "./attachments/Attachments";
import Mime from "mime";
import Queue from "./Queue";
import Subtitles from "./Subtitles";
import Screen from "./Screen";
import Lyrics, { getLyricsItemsFromContent } from "./Lyrics";
import Bible from "./Bible";
import History from "./History";
import Website from "./Website";
import Tabs from "./controls/Tabs";
import { v4 as uuidv4 } from "uuid";
import Banners from "./Banners";
import SongSheets from "./SongSheets";
import Overlay from "./Overlay";
import WebSocket from "./WebSocket";
import EditControl from "./controls/EditControl";
import { useParams } from "react-router-dom";
import { isElectron } from "../utils/electron";
import PictureIcon from "../resources/picture-icon.svg";
import VideoIcon from "../resources/video-icon.svg";
import TitleIcon from "../resources/title-icon.svg";

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function handleDownloaded(items, setDownloadedMessage, setDownloaded) {
  const itemMediaHash = items
    .filter((item) => item.path)
    .map((item) => {
      let path = item.path;
      while (path.startsWith("/")) {
        path = path.substring(1);
      }
      if (path.indexOf("/") !== -1) {
        path = path.substring(0, path.indexOf("/"));
      }
      return path;
    });

  postUrl("http://localhost:8199/api/download", itemMediaHash).then(
    (response) => {
      const keys = Object.keys(response.data);
      setDownloaded(response.data);
      let totalKeys = keys.length;
      let totalDone = keys.filter((key) => response.data[key].done).length;
      let totalDownloaded = keys.filter(
        (key) => response.data[key].downloaded
      ).length;
      if (totalDone < totalKeys) {
        setDownloadedMessage("Downloading " + totalDone + "/" + totalKeys);
        setTimeout(() => {
          handleDownloaded(items, setDownloadedMessage, setDownloaded);
        }, 1000);
      } else if (totalDownloaded < totalDone) {
        setDownloadedMessage(
          "Unable to download " + (totalDone - totalDownloaded) + " files"
        );
      } else {
        setDownloadedMessage("");
      }
    }
  );
}

export default function QueuePage(props) {
  const [playlistName, setPlaylistName] = useState(undefined);
  const [playlistEtag, setPlaylistEtag] = useState(undefined);
  const [items, setItems_] = useState([]);
  const [downloadedMessage, setDownloadedMessage] = useState(
    isElectron() ? "Downloading..." : ""
  );
  const [downloaded, setDownloaded] = useState({});
  const [historyItems, setHistoryItems] = useState([]);
  const [activeTab, setActiveTab] = useState(
    getParameterByName("tab") || "Preview"
  );
  const [isSelected, setIsSelected] = useState({});
  const [editMode, setEditMode] = useState(false || props.type === "readonly");
  const [queue, setQueue] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({});

  const params = useParams();
  const navigate = useNavigate();

  function onClickTabItem(tab) {
    if (!editMode && tab !== activeTab) {
      const href =
        window.location.pathname.split("?")[0] +
        "?tab=" +
        encodeURIComponent(tab);
      navigate(href);
      setActiveTab(tab);
    }
  }

  const reloadServiceInfo = useCallback(async () => {
    let si = await getUrl(
      "https://messaging.mvsda.org/bulletin/serviceinfo/" + playlistName
    );
    setServiceInfo(si.data);
  }, [playlistName]);

  const reloadPlaylist = useCallback(async () => {
    //reload Playlist
    let playlistResponse = await getUrl("/api/playlists/" + params.id);
    if (playlistResponse.status === 200) {
      setPlaylistName(playlistResponse.data.name);
      setPlaylistEtag(playlistResponse.data._etag);
      setItems_(playlistResponse.data.items);

      setBreadCrumbs([
        {
          name: "Playlists",
          link: `${process.env.PUBLIC_URL}/playlists`,
        },
        {
          name: playlistResponse.data.name,
          link: `${process.env.PUBLIC_URL}/playlists/` + params.id,
          _id: params.id,
          editField: "name",
          editLabel: "Edit Playlist",
        },
      ]);
    }

    //reload history
    let historyResponse = await getUrl("/api/history/" + params.id);
    if (historyResponse.status === 200) {
      if (
        historyResponse &&
        historyResponse.data &&
        Array.isArray(historyResponse.data)
      ) {
        setHistoryItems(historyResponse.data);
      }
    }
  }, [params.id]);

  useEffect(() => {
    reloadPlaylist();
    reloadServiceInfo();
    WebSocket.on("playlistupdate", (msg) => {
      const obj = JSON.parse(msg);
      if (obj._id === params.id) {
        reloadPlaylist(obj);
      }
    });
  }, [reloadPlaylist, reloadServiceInfo, params.id]);

  useEffect(() => {
    if (isElectron()) {
      handleDownloaded(items, setDownloadedMessage, setDownloaded);
    }
  }, [items]);

  async function setItems(items, savePlaylist = true) {
    setItems_(items);

    if (!savePlaylist) {
      return;
    }

    try {
      await putUrl("/api/playlists/" + params.id, {
        name: playlistName,
        _etag: playlistEtag,
        _id: params.id,
        items,
      });
    } catch (error) {
      if (String(error).indexOf("409") !== -1) {
        alert(
          "Someone else modified the playlist. Reloading playlist. Try the operation again."
        );
      } else {
        alert("Save failed: " + error + ". Please try the operation again.");
      }
    }
    await reloadPlaylist();
  }

  function handleAddAttachments(newItems) {
    setItems([...items, ...newItems]);
  }

  function handleAddBible(data) {
    let items1 = [...items];
    items1.push({
      type: "bible",
      name: data.reference,
      reference: data.reference,
      translation: data.translation,
      content: data.content,
      _id: uuidv4(),
    });
    setItems(items1);
  }

  function handleAddWebsite(data) {
    let items1 = [...items];
    items1.push({
      type: "iframe",
      name: data.name || "Website",
      url: data.url,
      _id: uuidv4(),
    });
    setItems(items1);
  }

  function handleAddLyrics(lyricsText) {
    setItems([...items, ...getLyricsItemsFromContent(lyricsText)]);
  }

  function getFirstSelectedItem() {
    return items.find((item) => isSelected[item._id]);
  }

  function removeHtmlTags(text) {
    if (!text) {
      return text;
    }
    while (true) {
      const posOpen = text.indexOf("<");
      if (posOpen !== -1) {
        const posClose = text.indexOf(">", posOpen + 1);
        if (posClose !== -1) {
          text = text.substring(0, posOpen) + text.substring(posClose + 1);
        } else {
          return text;
        }
      } else {
        return text;
      }
    }
  }

  function showItem(item) {
    let label = item.name;
    if (item.assignedTo) {
      label = item.name + " (" + item.assignedTo + ")";
    }
    label = removeHtmlTags(label);
    while (label && label.indexOf("[[") !== -1) {
      let pos = label.indexOf("[[");
      let posEnd = label.indexOf("]]", pos);
      if (posEnd === -1) {
        label = label.substring(0, pos) + label.substring(pos + 2);
      } else {
        let key = label.substring(pos + 2, posEnd);
        let value = serviceInfo[key] || "";
        label = label.substring(0, pos) + value + label.substring(posEnd + 2);
      }
    }
    if (item.bulletinType === "section") {
      label = <b>{label}</b>;
    } else if (item.bulletinType === "subitem") {
      label = <span>&nbsp;&nbsp;{label}</span>;
    }
    let hash = item.path || "";
    while (hash.startsWith("/")) {
      hash = hash.substring(1);
    }
    if (hash.indexOf("/") !== -1) {
      hash = hash.substring(0, hash.indexOf("/"));
    }
    if (downloaded[hash]) {
      if (!downloaded[hash].downloaded) {
        return <span style={{ color: "#FF8888" }}>{label}</span>;
      }
    }

    let icon = TitleIcon;
    const contentType = Mime.getType(item.path || "");
    if (contentType?.startsWith("image/")) {
      icon = VideoIcon;
    } else if (contentType?.startsWith("video")) {
      icon = VideoIcon;
    } else if (item.type === "lyrics") {
      icon = PictureIcon;
    }
    const iconClassName = item.bulletinType
      ? "QueueItemIcon"
      : "QueueItemIcon opacity20";

    return (
      <>
        <img className={iconClassName} src={icon} alt="" />
        {label}
      </>
    );
  }

  return (
    <Banners breadcrumbs={breadCrumbs} msg={downloadedMessage}>
      <div className="flex-row">
        <Queue
          display={
            activeTab === "Song Sheets"
              ? "d-none d-lg-inline"
              : "d-none d-lg-inline d-print-inline"
          }
          items={items}
          setItems={setItems}
          setme={(me) => {
            setQueue(me);
          }}
          activeTab={activeTab}
          isSelected={isSelected}
          setIsSelected={(selected) => setIsSelected(selected)}
          readonly={editMode}
          showItem={showItem}
        />
        <div
          style={{
            flex: "1 1 70%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Tabs
            onClickTabItem={onClickTabItem}
            activeTab={activeTab}
            readonly={editMode}
          >
            <div
              label="Playlist"
              display="d-none d-sm-inline d-lg-none d-print-none"
            >
              <Queue
                display="d-sm-inline"
                items={items}
                setItems={setItems}
                setme={(me) => {
                  setQueue(me);
                }}
                activeTab={activeTab}
                isSelected={isSelected}
                setIsSelected={(selected) => setIsSelected(selected)}
                readonly={editMode}
                showItem={showItem}
              />
            </div>
            <div label="Preview" display="d-none d-sm-inline d-print-none">
              <Screen type="preview" />
            </div>
            <div label="Properties" display="d-none d-sm-inline d-print-none">
              <EditControl
                item={getFirstSelectedItem()}
                handleCancel={async () => {
                  await reloadPlaylist();
                  setEditMode(false);
                }}
                handleDelete={(item) => {
                  if (item._id) {
                    for (let i = 0; i < items.length; i++) {
                      if (items[i]._id === item._id) {
                        let items1 = [...items];
                        items1.splice(i, 1);
                        let isSelected1 = { ...isSelected };
                        delete isSelected1[item._id];
                        if (i < items1.length) {
                          isSelected1[items1[i]._id] = true;
                        } else if (items1.length >= 1) {
                          isSelected1[items1[i - 1]._id] = true;
                        }
                        setIsSelected(isSelected1);
                        setItems_(items1);
                        setEditMode(true);
                        break;
                      }
                    }
                  }
                }}
                setItem={(item) => {
                  if (item._id) {
                    for (let i = 0; i < items.length; i++) {
                      if (items[i]._id === item._id) {
                        let items1 = [...items];
                        items1[i] = { ...item };
                        setItems(items1, false);
                        setEditMode(true);
                        break;
                      }
                    }
                  }
                }}
                editMode={editMode}
                handleSaveAll={() => {
                  setItems(items);
                  setEditMode(false);
                }}
                handleDuplicate={(item) => {
                  if (item._id) {
                    for (let i = 0; i < items.length; i++) {
                      if (items[i]._id === item._id) {
                        let itemToInsert = JSON.parse(JSON.stringify(items[i]));
                        itemToInsert._id = uuidv4();
                        const items1 = [
                          ...items.slice(0, i + 1),
                          itemToInsert,
                          ...items.slice(i + 1),
                        ];
                        let isSelected1 = { ...isSelected };
                        isSelected1[item._id] = false;
                        isSelected1[itemToInsert._id] = true;
                        setIsSelected(isSelected1);
                        setItems_(items1);
                        setEditMode(true);
                        break;
                      }
                    }
                  }
                }}
              />
            </div>
            <div label="Media" display="d-none d-sm-inline d-print-none">
              <Attachments
                name="attachments"
                uploadOnly={false}
                label="label"
                error="error"
                readOnly={false}
                setRef={undefined}
                onAdd={handleAddAttachments}
                onExecute={(path) => {
                  handleAddAttachments(path);
                }}
                playlistName={playlistName}
              />
            </div>
            <div label="Lyrics" display="d-none d-sm-inline d-print-none">
              <Lyrics onAdd={handleAddLyrics} />
            </div>
            <div label="Website" display="d-none d-sm-inline d-print-none">
              <Website onAdd={handleAddWebsite} playlistitems={items} />
            </div>
            <div label="Bible" display="d-none d-sm-inline d-print-none">
              <Bible onAdd={handleAddBible} playlistitems={items} />
            </div>
            <div label="Subtitles" display="d-none d-sm-inline d-print-none">
              <Subtitles items={items} />
            </div>
            <div label="Presentation" display="d-none d-sm-inline d-print-none">
              <Screen
                type="preview"
                doPrev={() => {
                  let queue1 = queue;
                  if (queue1) {
                    let item = getFirstSelectedItem();
                    if (item) {
                      let index = -1;
                      for (let i = 0; i < items.length; i++) {
                        if (Queue.instance.isPlaying(i)) {
                          index = i;
                        }
                      }
                      if (index > 0) {
                        Queue.instance.onSelect(index - 1, index - 1);
                      }
                    }
                  }
                }}
                doNext={() => {
                  let queue1 = queue;
                  if (queue1) {
                    let item = getFirstSelectedItem();
                    if (item) {
                      let index = -1;
                      for (let i = 0; i < items.length; i++) {
                        if (Queue.instance.isPlaying(i)) {
                          index = i;
                        }
                      }
                      if (index >= 0 && index + 1 < items.length) {
                        Queue.instance.onSelect(index + 1, index + 1);
                      }
                    }
                  }
                }}
              />
            </div>
            <div label="Song Sheets" display="d-none d-sm-inline d-print-none">
              <SongSheets items={items} playlistName={playlistName} />
            </div>
            <div label="Overlay" display="d-none d-sm-inline d-print-none">
              <Overlay items={items} />
            </div>
            <div label="History" display="d-none d-sm-inline d-print-none">
              <History id={params.id} items={historyItems} />
            </div>
          </Tabs>
        </div>
      </div>
    </Banners>
  );
}
