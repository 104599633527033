import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Mime from "mime";

export default function EditControl(props) {
  function handleChange({ currentTarget: input }) {
    let data_ = {
      ...props.item,
    };
    if (
      input.name === "controls" ||
      input.name === "loop" ||
      input.name === "muted" ||
      input.name === "playNext"
    ) {
      if (data_[input.name] === true) {
        data_[input.name] = false;
      } else {
        data_[input.name] = true;
      }
    } else {
      data_[input.name] = input.value;
    }
    props.setItem(data_);
  }

  function handleChangeLyrics({ currentTarget: input }) {
    let data_ = {
      ...props.item,
    };
    if (input.name === "lyricsTitle") {
      data_.lyric = { ...data_.lyric };
      data_.lyric.Title = { ...data_.lyric.Title };
      data_.lyric.Title.text = [input.value];
    } else if (input.name === "lyricsSubtitle") {
      data_.lyric = { ...data_.lyric };
      data_.lyric.Subtitle = { ...data_.lyric.Subtitle };
      data_.lyric.Subtitle.text = [input.value];
    } else if (input.name === "lyricsBody") {
      data_.lyric = { ...data_.lyric };
      data_.lyric.Body = { ...data_.lyric.Body };
      data_.lyric.Body.text = input.value.split("\n");
    } else if (input.name === "lyricsCopyright") {
      data_.lyric = { ...data_.lyric };
      data_.lyric.Copyright = { ...data_.lyric.Copyright };
      data_.lyric.Copyright.text = input.value.split("\n");
    } else if (input.name === "lyricsBackground") {
      data_.lyric = { ...data_.lyric };
      if (!isNaN(input.value)) {
        data_.lyric.background = parseInt(input.value);
      } else {
        data_.lyric.background = input.value;
      }
    }
    props.setItem(data_);
  }

  function handleChangeMediaNotes({ currentTarget: input }) {
    let data_ = {
      ...props.item,
    };
    if (input.name === "mediaNotes") {
      data_.mediaNotes = input.value;
    }
    props.setItem(data_);
  }

  function render() {
    if (!props.item) {
      return <h2>Select item from the playlist</h2>;
    }
    let {
      name,
      bulletinType,
      assignedTo,
      type,
      url,
      path,
      controls,
      loop,
      muted,
      playNext,
      lyric,
      mediaNotes,
      _id,
      starttime,
      endtime,
    } = props.item;
    let typeLabel =
      type === "media"
        ? "Media"
        : type === "lyrics"
        ? "Lyrics"
        : type === "iframe"
        ? "Website"
        : type === "blank"
        ? "Blank"
        : type;

    return (
      <React.Fragment>
        <Modal.Footer>
          <h3>Edit {typeLabel}</h3>
          <div style={{ flex: "1 1 auto" }}></div>
          {_id && (
            <Button
              variant="danger"
              onClick={() => props.handleDelete(props.item)}
            >
              Delete
            </Button>
          )}
          <Button
            variant="secondary"
            onClick={() => props.handleDuplicate(props.item)}
          >
            Duplicate
          </Button>
          <Button
            variant="secondary"
            onClick={props.handleCancel}
            disabled={!props.editMode}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              props.handleSaveAll();
            }}
            disabled={!props.editMode}
          >
            Save
          </Button>
        </Modal.Footer>
        <div className="p-2">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              className="w-100"
              type="input"
              name="name"
              value={name || ""}
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  props.submit();
                }
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">In Bulletin</label>
            <select
              className="w-100"
              name="bulletinType"
              value={bulletinType || ""}
              onChange={handleChange}
            >
              <option value="">None</option>
              <option value="section">Section</option>
              <option value="item">Item</option>
              <option value="subitem">Subitem</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="assignedTo">Assigned to</label>
            <input
              className="w-100"
              type="input"
              name="assignedTo"
              value={assignedTo || ""}
              onChange={handleChange}
            />
          </div>
          {(type === "image" || type === "video" || type === "iframe") && (
            <div className="form-group">
              <label htmlFor="url">URL</label>
              <input
                type="input"
                name="url"
                value={url || ""}
                onChange={handleChange}
                className="w-100"
              />
            </div>
          )}
          {type === "media" ? (
            <div className="form-group">
              <label htmlFor="path">Path</label>
              <input
                readOnly={true}
                name="path"
                value={path || "/"}
                className="w-100"
              />
            </div>
          ) : (
            ""
          )}
          {(type === "video" ||
            type === "audio" ||
            (type === "media" &&
              path &&
              Mime.getType(path) &&
              (Mime.getType(path).startsWith("video/") ||
                Mime.getType(path).startsWith("audio/")))) && (
            <React.Fragment>
              <div className="form-group">
                <label htmlFor="starttime">Start time</label>
                <input
                  type="text"
                  id="starttime"
                  name="starttime"
                  value={starttime || ""}
                  onChange={handleChange}
                  className="w-100"
                />
              </div>
              <div className="form-group">
                <label htmlFor="endtime">End time</label>
                <input
                  type="text"
                  id="endtime"
                  name="endtime"
                  value={endtime || ""}
                  onChange={handleChange}
                  className="w-100"
                />
              </div>
              <table border="0">
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        id="controls"
                        name="controls"
                        checked={controls}
                        onChange={handleChange}
                      />
                      <label htmlFor="controls">Show Controls on Screen</label>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        id="loop"
                        name="loop"
                        checked={loop}
                        onChange={handleChange}
                      />
                      <label htmlFor="loop">Loop Video</label>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        id="muted"
                        name="muted"
                        checked={muted}
                        onChange={handleChange}
                      />
                      <label htmlFor="muted">Mute video</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        id="playNext"
                        name="playNext"
                        checked={playNext === true}
                        onChange={handleChange}
                      />
                      <label htmlFor="playNext">Play Next</label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          )}
          {type === "lyrics" && (
            <React.Fragment>
              <div className="form-group">
                <label htmlFor="lyricsTitle">Title</label>
                <input
                  type="text"
                  id="lyricsTitle"
                  name="lyricsTitle"
                  value={lyric.Title.text[0] || ""}
                  onChange={handleChangeLyrics}
                  className="w-100"
                ></input>
              </div>
              <div className="form-group">
                <label htmlFor="lyricsSubtitle">Subtitle</label>
                <input
                  type="text"
                  id="lyricsSubtitle"
                  name="lyricsSubtitle"
                  value={lyric.Subtitle.text[0] || ""}
                  onChange={handleChangeLyrics}
                  rows="10"
                  cols="80"
                  className="w-100"
                ></input>
              </div>
              <div className="form-group">
                <label htmlFor="lyricsBody">Body</label>
                <textarea
                  type="text"
                  id="lyricsBody"
                  name="lyricsBody"
                  value={lyric.Body.text.join("\n") || ""}
                  onChange={handleChangeLyrics}
                  rows="10"
                  cols="80"
                  className="w-100"
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="lyricsCopyright">Copyright</label>
                <textarea
                  type="text"
                  id="lyricsCopyright"
                  name="lyricsCopyright"
                  value={lyric.Copyright.text.join("\n") || ""}
                  onChange={handleChangeLyrics}
                  rows="10"
                  cols="80"
                  className="w-100"
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="lyricsBackground">Background</label>
                <input
                  type="text"
                  id="lyricsBackground"
                  name="lyricsBackground"
                  value={String(lyric.background || "")}
                  onChange={handleChangeLyrics}
                  rows="10"
                  cols="80"
                  className="w-100"
                ></input>
              </div>
            </React.Fragment>
          )}
          {type === "media" && (
            <React.Fragment>
              <div className="form-group">
                <label htmlFor="mediaNotes">Notes</label>
                <textarea
                  type="text"
                  id="mediaNotes"
                  name="mediaNotes"
                  value={mediaNotes || ""}
                  onChange={handleChangeMediaNotes}
                  rows="10"
                  cols="80"
                  className="w-100"
                ></textarea>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }

  return render();
}
