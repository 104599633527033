import React, { useState, useEffect } from "react";
import WebSocket from "./WebSocket";
import Config from "../utils/config";
import Banners from "./Banners";
import { BrowserWindow } from "../utils/electron";
import authService from "../utils/authService";

export default function Settings(props) {
  const [users, setUsers] = useState([]);
  const [additionalLyrics, setAdditionalLyrics] = useState(
    Config.getAdditionalLyrics()
  );
  const [notesFontSize, setNotesFontSize] = useState(
    localStorage.getItem("NotesFontSize") || "8vw"
  );
  const [notesPadding, setNotesPadding] = useState(
    localStorage.getItem("NotesPadding") || "0"
  );
  const [atemControlSoftwareIp, setAtemControlSoftwareIp] = useState(
    Config.getAtemControlSoftwareIp()
  );
  const [atemMixerIp, setAtemMixerIp] = useState(Config.getAtemMixerIp());
  const [remoteScreenIp, setRemoteScreenIp] = useState(
    Config.getRemoteScreenIp()
  );

  useEffect(() => {
    WebSocket.sendDeviceInfo();
    setUsers([]);
  }, []);

  function handleLyricsChange({ currentTarget: input }) {
    let additionalLyrics1 = [...additionalLyrics];
    const pos = additionalLyrics1.indexOf(input.name);
    if (pos !== -1) {
      additionalLyrics1.splice(pos, 1);
    } else {
      additionalLyrics1.push(input.name);
    }
    Config.setAdditionalLyrics(additionalLyrics1);
    setAdditionalLyrics(additionalLyrics1);
  }

  return (
    <div className="flex-column">
      <Banners
        breadcrumbs={[
          {
            name: "Settings",
            link: `${process.env.PUBLIC_URL}/settings`,
          },
        ]}
      >
        Name:{" "}
        <input
          name="name"
          id="name"
          value={Config.getName()}
          onChange={({ currentTarget: input }) => {
            Config.setName(input.value);
          }}
        />
        <br />
        Device ID: {Config.getDeviceId()}
        <br />
        Device Secret: {Config.getDeviceSecret()}
        <br />
        Devices with play permissions:{" "}
        <input
          name="playpermissions"
          id="playpermissions"
          value={Config.getPlaypermissions()}
          onChange={({ currentTarget: input }) => {
            Config.setPlaypermissions(input.value);
          }}
        />
        <br />
        <button
          onClick={async () => {
            const browserWindows = await BrowserWindow.getAllWindows();
            browserWindows.forEach(async (browserWindow) => {
              await (await browserWindow.getWebContents()).openDevTools();
            });
          }}
        >
          Launch devtools
        </button>
        <br />
        Incliude Lyrics from:
        <br />
        {users
          .filter((user) => user._id !== authService.getProfile().token)
          .map((user) => (
            <div key={user._id}>
              <input
                type="checkbox"
                name={user._id}
                id={user._id}
                checked={additionalLyrics.indexOf(user._id) !== -1}
                onChange={handleLyricsChange}
              />
              {user.email}
            </div>
          ))}
        <br />
        <h2>Notes</h2>
        Font size:{" "}
        <input
          type="text"
          value={notesFontSize}
          onChange={({ currentTarget: input }) => {
            localStorage.setItem("NotesFontSize", input.value || "8vw");
            setNotesFontSize(input.value || "8vw");
          }}
        />
        <br />
        Padding:{" "}
        <input
          type="text"
          value={notesPadding}
          onChange={({ currentTarget: input }) => {
            localStorage.setItem("NotesPadding", input.value || "0");
            setNotesPadding(input.value || "0");
          }}
        />
        <br />
        <h2>ATEM Mixer</h2>
        Control Software IP{" "}
        <input
          type="text"
          value={atemControlSoftwareIp}
          onChange={({ currentTarget: input }) => {
            Config.setAtemControlSoftwareIp(input.value);
            setAtemControlSoftwareIp(input.value);
          }}
        />
        <br />
        Mixer IP:{" "}
        <input
          type="text"
          value={atemMixerIp}
          onChange={({ currentTarget: input }) => {
            Config.setAtemMixerIp(input.value);
            setAtemMixerIp(input.value);
          }}
        />
        <h2>Remote Screen</h2>
        Remote Screen IP{" "}
        <input
          type="text"
          value={remoteScreenIp}
          onChange={({ currentTarget: input }) => {
            Config.setRemoteScreenIp(input.value);
            setRemoteScreenIp(input.value);
          }}
        />
        <br />
      </Banners>
    </div>
  );
}
