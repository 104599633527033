import React from "react";
import Config from "../utils/config";

export function getDefaultScreens() {
  let ret = [
    { id: "screen", label: "Screen", enabled: false, size: 100 },
    { id: "stage", label: "Stage", enabled: false, size: 100 },
    { id: "subtitles", label: "Subtitles", enabled: false, size: 100 },
    { id: "novideo", label: "Novideo", enabled: false, size: 100 },
  ];
  if (Config.getAtemControlSoftwareIp() && Config.getAtemMixerIp()) {
    ret.push({
      id: "atem",
      label: "ATEM",
      enabled: true,
      size: 100,
    });
  }
  return ret;
}

export default function ScreenSelection(props) {
  return (
    <div className="flex-column-fixed">
      {props.screens.map((screen, index) => (
        <div className="flex-row" key={screen.id}>
          <input
            style={{ margin: "5px" }}
            type="checkbox"
            name={screen.id}
            checked={screen.enabled}
            onChange={({ currentTarget: input }) => {
              let screens = [...props.screens];
              for (let i = 0; i < screens.length; i++) {
                if (screens[i].id === input.name) {
                  screens[i].enabled = input.checked;
                }
              }
              props.onChange(screens);
            }}
          />
          <div className="flex-row">{screen.label}</div>
          <button
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
            onClick={() => {
              let screens = [...props.screens];
              screens[index].size -= 5;
              props.onChange(screens);
            }}
          >
            -
          </button>
          <input
            name={screen.id}
            value={String(screen.size)}
            onChange={({ currentTarget: input }) => {
              let screens = [...props.screens];
              for (let i = 0; i < screens.length; i++) {
                if (screens[i].id === input.name) {
                  screens[i].size = parseInt(input.value);
                }
              }
              props.onChange(screens);
            }}
          ></input>
          <button
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
            onClick={() => {
              let screens = [...props.screens];
              screens[index].size += 5;
              props.onChange(screens);
            }}
          >
            +
          </button>
        </div>
      ))}
    </div>
  );
}
